import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

// 报表
export function GetReportList() {
  const loginKey = getToken()
  return request({
    url: './report/GetReportList',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function GetReportList_af() {
  const loginKey = getToken()
  return request({
    url: './report/GetReportList_af',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function GetReportParam(ReportID) {
  const loginKey = getToken()
  return request({
    url: './report/GetReportParam',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ReportID
    })
  })
}

export function GetReportData(ReportID, paras, pageIndex, pageSize) {
  const loginKey = getToken()
  return request({
    url: './report/GetReportData',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ReportID,
      paras,
      pageIndex,
      pageSize
    }),
    timeout: 300000
  })
}

export function GetReportDataPage(ReportID, paras, pageIndex, pageSize) {
  const loginKey = getToken()
  return request({
    url: './report/GetReportDataPage',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      ReportID,
      paras,
      pageIndex,
      pageSize
    }),
    timeout: 60000
  })
}

export function ExportData(ReportID, paras, name, fields) {
  const loginKey = getToken()
  return request({
    url: './report/ExportData',
    method: 'post',
    responseType: 'blob',
    data: Object.assign({}, commonParams, {
      loginKey,
      ReportID,
      paras,
      name,
      fields
    }),
    timeout: 300000
  })
}

export function ExportTrip(ObjectID, startTime, endTime) {
  const loginKey = getToken()
  return request({
    url: './query/ExportTrip',
    method: 'post',
    responseType: 'blob',
    timeout: 60000,
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID,
      startTime,
      endTime
    })
  })
}

export function ExportPark(ObjectID, limitMinutes, startTime, endTime) {
  const loginKey = getToken()
  return request({
    url: './query/ExportPark',
    method: 'post',
    responseType: 'blob',
    timeout: 60000,
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID,
      limitMinutes,
      startTime,
      endTime
    })
  })
}
