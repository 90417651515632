<template>
  <div>
    <el-row>
      <el-select v-model="province" class="province" v-if="!!originData">
        <el-option v-for="(k, index) in originData.result.result.data" v-if="!!k.carorg"  :key="index" :label="k.province" :value="k.carorg"></el-option>
      </el-select>
      <el-select v-model="city" class="city" v-if="cityList.length > 0">
        <el-option v-for="(k, index) in cityList" :key="index" :label="k.city" :value="k.carorg"></el-option>
      </el-select>
      <!-- <el-select v-model="city">
        <el-option v-for="(k, index) in originData.result.result.data" :key="index" :label="k.label" :value="k.value"></el-option>
      </el-select> -->
    </el-row>
    <el-row class="offsetRow">
      <span class="offsetLabel">车牌号码</span>
      <el-input v-model="valueInner.pChepai" placeholder="请输入车牌号码">
        <span slot="prepend">{{provinceItem && provinceItem.lsprefix}}</span>
      </el-input>
    </el-row>
    <el-row class="offsetRow" v-if="provinceItem && provinceItem.engineno != 0">
      <span class="offsetLabel" >发动机号</span>
      <el-input v-model="valueInner.pEngineno" placeholder="请输入后4位发动机号" v-if="provinceItem.engineno == 4"></el-input>
      <el-input v-model="valueInner.pEngineno" placeholder="请输入后6位发动机号" v-if="provinceItem.engineno == 6"></el-input>
      <el-input v-model="valueInner.pEngineno" placeholder="请输入全部发动机号" v-if="provinceItem.engineno == 100"></el-input>
    </el-row>
    <el-row class="offsetRow noMargin" v-if="provinceItem && provinceItem.frameno != 0">
      <span class="offsetLabel">车架号码</span>
      <el-input v-model="valueInner.pFrameno" placeholder="请输入后4位车架号" v-if="provinceItem.frameno == 4"></el-input>
      <el-input v-model="valueInner.pFrameno" placeholder="请输入后6位车架号" v-if="provinceItem.frameno == 6"></el-input>
      <el-input v-model="valueInner.pFrameno" placeholder="请输入全部车架号" v-if="provinceItem.frameno == 100"></el-input>
    </el-row>
  </div>
</template>

<script>
import * as API from '@/api/risk'
import { findFromArray } from '@/common/utils'
export default {
  props: {
    value: {
      default: {
        "pChepai": "",
        "pLsprefix": "京",
        "pCarorg": "beijing",
        "pFrameno": "",
        "pEngineno": ""
      }
    }
  },
  data() {
    return {
      originData: null,
      valueInner: this.value,
      province: 'beijing',
      city: ''
    }
  },
  watch: {
    province() {
      if (this.cityList.length > 0) {
        this.city = this.cityList[0].carorg
      } else {
        this.city = ''
      }
      this.emitValue()
    },
    'city': 'emitValue'
  },
  computed: {
    provinceItem() {
      if (!this.originData) return null

      return findFromArray(this.originData.result.result.data, k => k.carorg === this.province)
    },
    cityList() {
      if (!this.provinceItem) return []
      return this.provinceItem.list || []
    }
  },
  methods: {
    emitValue() {
      this.valueInner.pLsprefix = this.provinceItem.lsprefix
      this.valueInner.pCarorg = this.city ? this.city : this.province
      this.$emit('input', this.valueInner)
    }
  },
  created() {
    API.MCheliangweizhangOptions().then(ret => {
      this.originData = ret.data
    })
  }
}
</script>

<style lang="scss" scoped>
.province {
  width: 100px;
  margin-right: 10px;
}
.city {
  width: 180px;
}
.el-row:not(.noMargin) {
  margin-bottom: 18px;
}
.offsetRow {
  position: relative;
  margin-left: -6em;
  padding-left: 6em;
  .offsetLabel {
    position: absolute;
    left: 0;
    top: 0;
    text-align: right;
    width: 6em;
    padding-right: 12px;
  }
  .el-input {
  }
}
</style>

