<template>
  <div v-show="items && items.length">
    <div ref="container" class="container" id="container"
      :style="{height: `${chartHeight}px`, marginRight:'48px', marginTop: '24px'}"></div>
  </div>
</template>
<script>
  // import { remoteLoad } from '@/common/utils'
  // import { DOWNLOAD_URL } from '@/api/config'
  import * as G2 from '@antv/g2';
  import miment from 'miment'
  export default {
    props: {
      items: {
        type: Array,
        default: () => ([])
      },
      demo: {
        type: Boolean,
        default: false
      },
      chartWidth: {
        type: Number,
        default: 350
      },
      title: {
        type: String,
        default: ''
      },
      chartHeight: {
        type: Number,
        default: 180
      }
    },
    data() {
      return {
        // mountedFlg: false,
        chart_online: null
      }
    },
    mounted() {
      let arr = []
      if (!this.items || !this.items.length) {
        arr = []
      } else {
        let items = this.items
        let maxOil = items[0].OilNum;
        let minOil = items[0].OilNum;
        let maxSpeed = items[0].Speed;
        let maxAcc = items[0].ACC;
        let maxMileage = items[0].Mileage;
        for (let i = 0; i < items.length - 1; i++) {
          maxOil = maxOil < items[i + 1].OilNum ? items[i + 1].OilNum : maxOil
          minOil = minOil > items[i + 1].OilNum ? items[i + 1].OilNum : minOil
          maxSpeed = maxSpeed < items[i + 1].Speed ? items[i + 1].Speed : maxSpeed
          maxAcc = maxAcc < items[i + 1].ACC ? items[i + 1].ACC : maxAcc
          maxMileage = maxMileage < items[i + 1].Mileage ? items[i + 1].Mileage : maxMileage
        }
        items.forEach((k) => {
          arr.push({
            RcvTime: miment(k.RcvTime).format('YYYY-MM-DD hh:mm:ss'),
            OilNum: k.ACC,
            city: 'ACC',
            value: k.ACC ? maxOil.toFixed(0) : minOil.toFixed(0)
          }, {
            RcvTime: miment(k.RcvTime).format('YYYY-MM-DD hh:mm:ss'),
            OilNum: k.Speed,
            city: 'Speed',
            value: k.Speed ? ((k.Speed / 240) * maxOil).toFixed(0) : minOil.toFixed(0) - 1
          }, {
            RcvTime: miment(k.RcvTime).format('YYYY-MM-DD hh:mm:ss'),
            OilNum: k.OilNum,
            city: 'Oil',
            value: parseInt(k.OilNum)
          })
          // , {
          //   RcvTime: miment(k.RcvTime).format('YYYY-MM-DD hh:mm:ss'),
          //   OilNum: k.Mileage,
          //   city: 'Mileage',
          //   value: k.Mileage ? ((k.Mileage / maxMileage) * maxOil).toFixed(0) : minOil
          // }
        })
      }
      if (this.chart_online) {
        this.chart_online.data(arr || [])
        this.chart_online.render(arr || [])
      } else {
        this.renderCanvasJs(arr || [])
      }

      // this.mountedFlg = true
    },
    watch: {
      items(data) {
        // if (!this.mountedFlg) {
        //   return
        // }
        let items = data
        console.log(items)
        let arr = []
        let maxOil = items[0].OilNum;
        let minOil = items[0].OilNum;
        let maxSpeed = items[0].Speed;
        let maxAcc = items[0].ACC;
        let maxMileage = items[0].Mileage;
        for (let i = 0; i < items.length - 1; i++) {
          maxOil = maxOil < items[i + 1].OilNum ? items[i + 1].OilNum : maxOil
          minOil = minOil > items[i + 1].OilNum ? items[i + 1].OilNum : minOil
          maxSpeed = maxSpeed < items[i + 1].Speed ? items[i + 1].Speed : maxSpeed
          maxAcc = maxAcc < items[i + 1].ACC ? items[i + 1].ACC : maxAcc
          maxMileage = maxMileage < items[i + 1].Mileage ? items[i + 1].Mileage : maxMileage
        }
        console.log(maxOil, minOil, maxSpeed, maxAcc, maxMileage)

        items.forEach((k) => {
          arr.push({
            RcvTime: miment(k.RcvTime).format('YYYY-MM-DD hh:mm:ss'),
            OilNum: k.ACC,
            city: 'ACC',
            value: k.ACC ? maxOil.toFixed(0) : minOil.toFixed(0)
          }, {
            RcvTime: miment(k.RcvTime).format('YYYY-MM-DD hh:mm:ss'),
            OilNum: k.Speed,
            city: 'Speed',
            value: k.Speed ? ((k.Speed / 240) * maxOil).toFixed(0) : minOil.toFixed(0) - 1
          }, {
            RcvTime: miment(k.RcvTime).format('YYYY-MM-DD hh:mm:ss'),
            OilNum: k.OilNum,
            city: 'Oil',
            value: parseInt(k.OilNum)
          })
        });
        this.chart_online.data(arr || []);
        this.chart_online.render(arr || []);
      }
    },
    methods: {
      renderCanvasJs(data) {
        this.chart_online = new G2.Chart({
          container: 'container', // 指定图表容器 ID
          autoFit: true,
          width: 1440, // 指定图表宽度
          height: this.chartHeight, // 指定图表高度
          padding: [20, 10, 80, 80]
        });
        // Step 2: 载入数据源
        this.chart_online.source(data);
        // this.chart_online.scale({
        //   RcvTime: {
        //     range: [0, 1],
        //   },
        //   value: {
        //     nice: true,
        //   },
        // });
        this.chart_online.scale({
          // tick: 3,
          // tickInterval:4,
          RcvTime: {
            // tick: 3,
            tickCount: 8,
            type: 'cat'
            // formatter: (val) => {
            //   console.log(val)
            //   return val;
            // },
          },
          value: {
            type: 'linear'
          }
        });
        this.chart_online.tooltip({
          showCrosshairs: true,
          shared: true,
        });

        this.chart_online.axis('value', {
          label: {
            formatter: (val) => {
              return val + ' L';
            },
          },
        });
        this.chart_online
          .line()
          .position('RcvTime*value')
          .color('city').tooltip('city*value*OilNum', (city, value, OilNum) => {
            // percent = percent * 1000 / 10 + '%';
            // let d = value
            // if (city !== "oil") {
            //   d = OilNum
            // }
            return {
              name: city,
              value: city !== "Oil" ? OilNum : value,
            };
          });
        // this.chart_online
        //   .point()
        //   .position('RcvTime*value')
        //   .color('city')
        //   .shape('circle');
        this.chart_online.render();
      }
    }
  }

</script>

<style lang="scss" scoped>
  .container ::v-deep {
    .canvasjs-chart-toolbar {
      right: -7px !important;
      top: -7px !important;

      button {
        background-color: #F4F4F8 !important;

        &:hover {
          background-color: #CC3333 !important;
        }
      }
    }

    .canvasjs-chart-credit {
      display: none !important;
    }
  }

</style>
