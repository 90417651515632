<template>
  <!-- <main-wrapper tool-background-image="linear-gradient(135deg, #409EFF 0%, #2893A2 24%, #E8EAF6 100%)" tool-color="#FFF">
    <template slot="tool">
      <span class="sub-title ipx-1" @click="mountedFlag=!mountedFlag">报表查询</span>
    </template> -->
    <div class="v-wrapper tool--none" :class="$i18n.locale" v-loading="!mountedFlag">
      <div class="v-nav" v-show="menuVisible">

        <el-popover
          v-model="popoverMenu"
          transition="el-zoom-in-center"
          placement="right-start"
          trigger="click"
          ref="popover"
          popper-class="v-select-box--options vertical">

          <div slot="reference" class="report-query-item report-query-item--all" :key="-1">
            <!-- <i class="el-icon-menu item__icon" style="visibility:hidden;"></i> -->
            <span class="item__name">{{$t('reportQuery.allReport')}}<i class="el-icon-caret-right"></i></span>
          </div>

          <div class="v-select-box--options__block" v-for="k in list" :key="`option_${k.FunID}`">
            <p class="block__title"><i-icon :name="getIcon(k.FunName)" style="font-size:18px;margin-right:8px;opacity:.8;"></i-icon>{{$t('reportMenu')[k.FunName] || k.FunName}}</p>
            <div class="block__content">
              <div class="content-item" v-for="item in k.Children" :key="`item_${item.FunID}`" @click="changeItem(item)">
                {{$t('reportMenu')[item.FunName] || item.FunName}}
                <div class="icons" @click.stop="toggleLike(item)">
                  <!-- <i class="el-icon-star-on" style="color: #00ACC1;" v-if="isLike(item)"></i>
                  <i class="el-icon-star-off" style="color: #00ACC1;" v-else></i> -->

                  <i-icon name="iconxinxiguanli_yiguanzhu" v-if="isLike(item)" ></i-icon>
                  <i-icon name="iconxinxiguanli_weiguanzhu" v-else></i-icon>
                </div>
              </div>
            </div>
          </div>

        </el-popover>

        <transition-group name="slidegroup-report">
          <template v-for="k in list">
            <div class="report-query-item" v-if="isLike(item)" :class="{'active': currentReportItem===item}" v-for="item in k.Children" :key="item.FunID" @click="changeItem(item)">
              <!-- <i-icon class="item__icon" :name="item.image" v-if="item['image-type']==='svg'"></i-icon>
              <img class="item__icon" :src="item.image" v-else-if="item['image-type']==='img'"/> -->
              <!-- <i class="el-icon-minus item__icon" style="visibility:hidden;"></i> -->
              <span class="item__name">{{$t('reportMenu')[item.FunName] || item.FunName}}</span>
            </div>
          </template>
        </transition-group>
      </div>
      <arrow-button @click="menuVisible = !menuVisible" class="aside__toogle" :class="menuVisible ? '' : 'collapse'" :position="menuVisible ? 'right' : 'left'" :attach="false"></arrow-button>
      
      <div class="v-body" :class="{'fullscreen-item': isFullScreen}">
        <div class="retitle">
          {{$t('reportMenu')[currentReportItem.ParentFunName]}}
        </div>
        <ul class="retab">
          <ol v-for="(item, index) in currentReportItemList" :key="index" :class="{active:currentReportItem.FunID == item.FunID}" @click="() => changeItem(item)">{{$t('reportMenu')[item.FunName] || item.FunName}}</ol>
          <ol class="retab-expand" :key="-999"></ol>
        </ul>
        <div style="font-size:12px;">
        <div class="reform">
          <el-form key="risk__form" :model="form" inline :rules="rules" ref="form" label-position="top" :label-width="$i18n.locale==='zh'?'6em':'10em'" class="risk__form" :class="$i18n.locale" :validate-on-rule-change="false">
            <el-form-item v-if="hasUserParam" :label="$t('reportDetail')['用户名称']" key="hasUserParam">
              <el-select
                class="form-item--expand"
                v-model="form['@WhereHoldID']"
                @change="whereHoldChange"
                @clear="fetchUserList"
                clearable
                filterable
                remote
                reserve-keyword
                :placeholder="$t('common.inputText')"
                :remote-method="fetchUserList"
                :loading="loadingHold">
                <el-option
                  v-for="(item,index) in holdOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="hasUserParam2" :label="$t('reportDetail')['用户名称']" key="hasUserParam2">
              <el-select
                class="form-item--expand"
                v-model="form['@HoldID']"
                @change="whereHoldChange"
                @clear="fetchUserList"
                clearable
                filterable
                remote
                reserve-keyword
                :placeholder="$t('common.inputText')"
                :remote-method="fetchUserList"
                :loading="loadingHold">
                <el-option
                  v-for="(item,index) in holdOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="hasVehicleParam" :label="$t('reportDetail')['车牌/设备ID'] || '车牌/设备ID'" key="hasVehicleParam">
              <el-select
                class="form-item--expand"
                v-model="form['@WhereObjectID']"
                clearable
                filterable
                remote
                reserve-keyword
                :placeholder="$t('common.inputText')"
                :remote-method="fetchVehicleList"
                :loading="loadingVehicle"
                @clear="fetchVehicleList('')">
                <el-option
                  v-for="(item,index) in vehicleOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value">
                  <span style="float: left">{{ item.label }}</span>
                  <span style="float: left; color: #8492a6; font-size: 13px">{{ item.sim2 }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="hasVehicleParam2" :label="$t('reportDetail')['车牌/设备ID'] || '车牌/设备ID'" key="hasVehicleParam2">
              <el-select
                class="form-item--expand"
                v-model="form['@ObjectID']"
                clearable
                filterable
                remote
                reserve-keyword
                :placeholder="$t('common.inputText')"
                :remote-method="fetchVehicleList"
                :loading="loadingVehicle"
                @clear="fetchVehicleList('')">
                <el-option
                  v-for="(item,index) in vehicleOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value">
                  <span style="float: left">{{ item.label }}</span>
                  <span style="float: left; color: #8492a6; font-size: 13px">{{ item.sim2 }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-for="(item, index) in reportParmas.filter(k => k.ParaType != 'System' && k.SelectType!=3 && k.SelectType !=4)" :key="index" :label="$t('reportDetail')[item.ParaText.trim().replace(':','')] || item.ParaText" :prop="item.ParaName">
              <v-field v-model="form[item.ParaName]" v-bind="item" :key="item.RecID"></v-field>
            </el-form-item>
            <el-form-item class="requery" label="1">
              <el-button class="risk-submit border" type="primary" plain v-ripple @click="submit" :loading="btnLoading">{{$t('reportQuery.btnQuery')}}</el-button>
            </el-form-item>
          </el-form>
          <div class="button-group" v-if="!hasLongDateParam && !hasShortDateParam">
            <el-checkbox v-model="wrap" class="imr-2" :label="$t('onlineMonitor.lineFeed')"></el-checkbox>
            <el-button type="text" size="medium" @click="exportData" :loading="btnExportLoading"><i-icon name="icon-download"/> {{$t('reportQuery.export')}}</el-button>
          </div>
        </div>
        <div v-if="hasLongDateParam" class="quick_link" key="quick_link1">
          <p>
            <span class="i-link" @click="setDay1">{{$t('reportQuery.day1')}}</span>
            <span class="i-link" @click="setDay2">{{$t('reportQuery.day2')}}</span>
            <span class="i-link" @click="setDay3">{{$t('reportQuery.day3')}}</span>
            <span class="i-link" @click="setDay12">{{$t('reportQuery.day12')}}</span>
            <span class="i-link" @click="setDay13">{{$t('reportQuery.day13')}}</span>
            <span class="i-link" @click="setDay14">{{$t('reportQuery.day14')}}</span>
            <span class="i-link">|</span>
            <span class="i-link" @click="setWeek1">{{$t('reportQuery.week1')}}</span>
            <span class="i-link" @click="setWeek2">{{$t('reportQuery.week2')}}</span>
            <span class="i-link" @click="setWeek3">{{$t('reportQuery.week3')}}</span>
            <span class="i-link" @click="setWeek12">{{$t('reportQuery.week12')}}</span>
            <span class="i-link" @click="setWeek13">{{$t('reportQuery.week13')}}</span>
          </p>
          <div class="quick_btn">
            <el-button style="padding:0;float:right;" type="text" size="medium" @click="exportData" :loading="btnExportLoading"><i-icon name="icon-download"/> {{$t('reportQuery.export')}}</el-button>
            <el-checkbox v-model="wrap" style="padding-right: 12px;float:right;" :label="$t('onlineMonitor.lineFeed')"></el-checkbox>
          </div>
        </div>
        <div v-if="hasShortDateParam" class="quick_link" key="quick_link2">
          <p>
            <span class="i-link" @click="setMonth1">{{$t('reportQuery.month1')}}</span>
            <span class="i-link" @click="setMonth2">{{$t('reportQuery.month2')}}</span>
            <span class="i-link" @click="setMonth3">{{$t('reportQuery.month3')}}</span>
            <span class="i-link" @click="setMonth12">{{$t('reportQuery.month12')}}</span>
            <span class="i-link" @click="setMonth13">{{$t('reportQuery.month13')}}</span>
          </p>
          <div class="quick_btn">
            <el-button style="padding:0;float:right;" type="text" size="medium" @click="exportData" :loading="btnExportLoading"><i-icon name="icon-download"/> {{$t('reportQuery.export')}}</el-button>
            <el-checkbox v-model="wrap" style="padding-right: 12px;float:right;" :label="$t('onlineMonitor.lineFeed')"></el-checkbox>
          </div>
        </div>
        <!-- <el-scrollbar :wrap-style="`height:calc(100vh - ${this.spaceTableHeight}px);`" wrap-class="retable" class="retable-wrapper"> -->
          <div class="retable-wrapper">
          <!--显示报表表格-->
          <render-temperature11301 v-if="currentReportItem.FunID === 11301" key="render-temperature-11301" :items="table.data" :title="$t('reportMenu')[currentReportItem.FunName]" :chart-height="600"></render-temperature11301>
         <RenderOil v-if="currentReportItem.FunID === 11041" key="render-temperature-11301" :items="table.data" :title="$t('reportMenu')[currentReportItem.FunName]" :chart-height="300"></RenderOil>
         
          <!-- <render-oil v-if="currentReportItem.FunID === 11041" key="render-temperature-11301" :items="table.data" :title="$t('reportMenu')[currentReportItem.FunName]" :chart-height="600"><render-oil> -->
          <!--显示报表-->
          <!-- <el-table :data="table.data" class="v-table__customStyle" ref="reportTable" size="mini" border stripe style="width:100%" @header-dragend="headerDrag" height="calc(100vh - 335px)">
            <el-table-column
              v-for="(k, index) in showFieldS"
              :show-overflow-tooltip="!wrap"
              :label="$t('reportDetail')[k.name] || k.name"
              :prop="k.prop"
              :width="minColWidth"
              :key="index">
              <template slot-scope="{row}">
                <span v-if="k.prop.toLowerCase().includes('time')">{{row[k.prop] ? miment(row[k.prop]).format('YYYY-MM-DD hh:mm:ss') : row[k.prop]}}</span>
                <span v-else-if="k.prop.toLowerCase().includes('date')">{{row[k.prop] ? miment(row[k.prop]).format('YYYY-MM-DD') : row[k.prop]}}</span>
                <span v-else-if="k.prop.toLowerCase().includes('month')">{{row[k.prop] ? miment(row[k.prop]).format('YYYY-MM') : row[k.prop]}}</span>
                <span v-else-if="k.prop.toLowerCase().includes('hour')">{{row[k.prop] ? miment(row[k.prop]).format('hh:mm:ss') : row[k.prop]}}</span>
                <span v-else>{{row[k.prop]}}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-for="(k, index) in showFieldE"
              :show-overflow-tooltip="!wrap"
              :label="$t('reportDetail')[k.name] || k.name"
              :prop="k.prop"
              :min-width="lastCol"
              :key="index+999">
              <template slot-scope="{row}">
                <span v-if="k.prop.toLowerCase().includes('time')">{{row[k.prop] ? miment(row[k.prop]).format('YYYY-MM-DD hh:mm:ss') : row[k.prop]}}</span>
                <span v-else-if="k.prop.toLowerCase().includes('date')">{{row[k.prop] ? miment(row[k.prop]).format('YYYY-MM-DD') : row[k.prop]}}</span>
                <span v-else-if="k.prop.toLowerCase().includes('month')">{{row[k.prop] ? miment(row[k.prop]).format('YYYY-MM') : row[k.prop]}}</span>
                <span v-else-if="k.prop.toLowerCase().includes('hour')">{{row[k.prop] ? miment(row[k.prop]).format('hh:mm:ss') : row[k.prop]}}</span>
                <span v-else>{{row[k.prop]}}</span>
              </template>
            </el-table-column>
          </el-table> -->
          <table class="report-table" v-if="table.total > 0">
            <tr>
              <th v-for="(k, index) in showField" :key="index">{{$t('reportDetail')[k.name] || k.name}}</th>
            </tr>
            <tr v-for="(row, dIndex) in table.data" :key="dIndex">
              <td v-for="(k, index) in showField" :key="index">
                <span v-if="k.prop.toLowerCase().endsWith('time')">{{row[k.prop] ? miment(row[k.prop]).format('YYYY-MM-DD hh:mm:ss') : row[k.prop]}}</span>
                <span v-else-if="k.prop.toLowerCase().endsWith('date')">{{row[k.prop] ? miment(row[k.prop]).format('YYYY-MM-DD') : row[k.prop]}}</span>
                <span v-else-if="k.prop.toLowerCase().endsWith('month')">{{row[k.prop] ? miment(row[k.prop]).format('YYYY-MM') : row[k.prop]}}</span>
                <span v-else-if="k.prop.toLowerCase().endsWith('hour')">{{row[k.prop] ? miment(row[k.prop]).format('hh:mm:ss') : row[k.prop]}}</span>
                <span v-else>{{row[k.prop]}}</span>
              </td>
            </tr>
          </table>
          <el-pagination
            background
            v-if="table.total > 0"
            class="v-pager report-pager"
            :page-size="table.pageSize"
            :page-sizes="table.pageSizes"
            :current-page.sync="table.pageIndex"
            layout="total, sizes, prev, pager, next, jumper"
            :total="table.total"
            popper-class="custom"
            @current-change="currentChange"
            @size-change="sizeChange">
          </el-pagination>
          </div>
        <!-- </el-scrollbar> -->
        <!-- <div class="retable">

        </div> -->
        </div>
      </div>
    </div>
  <!-- </main-wrapper> -->
</template>

<script>
import { VclCode } from 'vue-content-loading'
import * as API from '@/api/report'
import {setReportLikeList, getReportLikeList, S_NEXT_REPORT_PARAM} from '@/common/cache'
import { deleteFromArray,saveBlob } from '@/common/utils'
import VField from '../risk/config/v-field'
import { QueryUserList } from '@/api/tree'
import { QueryTreeVehicleListVIP } from '@/api/tree'
import RenderTemperature11301  from './render-temperature11301'
import RenderOil  from './render-oil'
import miment from 'miment'
import storage from 'good-storage'
import ArrowButton from '@/components/arrow-button'

export default {
  name: 'report',
  components: {
    ArrowButton,
    VclCode,
    VField,
    RenderTemperature11301,
    RenderOil
  },
  data() {
    return {
      miment,
      list: [],
      likeList: [],
      currentReportItem: {},
      reportParmas: [],
      reportTableField: [],
      visibleRow: {},
      form: {},
      rules: {},
      mountedFlag: false,
      popoverMenu: false,
      btnLoading: false,
      btnExportLoading: false,
      loadingParam: false,
      loadingHold: false,
      loadingVehicle: false,
      holdOptions: [],
      vehicleOptions: [],
      scrollHeight: 0,
      isFullScreen: false,
      searchFormVisible: true,
      spaceTableHeight: 256,
      table: {
        data: [],
        total: 0,
        pageIndex: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 100]
      },
      wrap: true,
      lastCol: '100px',
      tabHeight: 'calc(100vh - 350px)',
      menuVisible: true,
      updateCount: 0
    }
  },
  created() {
    // 计算pageSize
    this.table.pageSize = Math.floor((window.innerHeight - 308 - 34) / 35 - 1)
    // 插入pageSizes
    if (!this.table.pageSizes.includes(this.table.pageSize)) {
      let index = 0
      for (; index < this.table.pageSizes.count; index++) {
        if (this.table.pageSize > this.table.pageSizes[index]) {
          break
        }
      }
      this.table.pageSizes.splice(index, 0, this.table.pageSize)
    }

    this.fetchList().then(() => {
      this.likeList = getReportLikeList()
      this.initItem()
    })
  },
  mounted() {
    // const resize = throttle(300, e => {
    //   this.scrollHeight = e.target.scrollTop
    // })
    // this.$refs.content.addEventListener('scroll', resize)
    // this.$once('hook:beforeDestroy', () => {
    //   this.$refs.content.removeEventListener('scroll', resize)
    // })

    // const scrollHandler = throttle(300, e => {
    //   if (e.deltaY > 0 && this.table.data.length !== 0 && this.searchFormVisible) {
    //     this.searchFormVisible = false
    //     e.preventDefault()
    //   }
    //   if (e.deltaY < 0 && this.$refs.content.scrollTop === 0 && !this.searchFormVisible) {
    //     this.searchFormVisible = true
    //     this.scrollHeight = this.$refs.content.scrollTop
    //     e.preventDefault()
    //   }
    // })
    // this.scrollHandler = scrollHandler
  },
  computed: {
    reportItemList() {
      return [].concat(...this.list.map(k => k.Children))
    },
    currentReportItemList() {
      if (!this.reportItemList) {
        return []
      }
      return this.reportItemList.filter(k => k.ParentFunName == this.currentReportItem.ParentFunName)
    },
    hasUserParam() {
      return this.reportParmas.map(k => k.ParaName).indexOf('@WhereHoldID') != -1
    },
    hasUserParam2() {
      return this.reportParmas.map(k => k.ParaName).indexOf('@HoldID') != -1
    },
    hasVehicleParam() {
      return this.reportParmas.map(k => k.ParaName).indexOf('@WhereObjectID') != -1
    },
    hasVehicleParam2() {
      return this.reportParmas.map(k => k.ParaName).indexOf('@ObjectID') != -1
    },
    currentTalbeList() {
      return this.table.data.slice((this.table.pageIndex - 1) * this.table.pageSize, (this.table.pageIndex - 1) * this.table.pageSize + this.table.pageSize)
    },
    showField() {
      return this.reportTableField.filter(k => this.isShowRow(k.name))
    },
    showFieldS() {
      return this.showField.slice(0, this.showField.length - 1)
    },
    showFieldE() {
      return this.showField.slice(this.showField.length - 1, this.showField.length)
    },
    hasShortDateParam() {
      return this.reportParmas.map(k => k.ValidationExpression).indexOf('yyyy-MM') != -1
    },
    hasLongDateParam() {
      return this.reportParmas.map(k => k.ValidationExpression).indexOf('yyyy-MM-dd HH:mm:ss') != -1 || this.reportParmas.map(k => k.ValidationExpression).indexOf('yyyy-MM-dd') != -1
    },
    minColWidth() {
      let min = parseInt((window.innerWidth - (this.menuVisible ? 390 : 140)) / this.showField.length - 2) + this.updateCount - this.updateCount + 'px'
      min = Math.max(parseInt(min), 150) + 'px';
      return min
    }
  },
  watch: {
    minColWidth(val) {
      this.lastCol = val
    },
    btnLoading(val) {
      if (this.timer_loading) {
        clearTimeout(this.timer_loading)
        this.timer_loading = null
      }

      if (val) {
        this.timer_loading = setTimeout(() => {
          this.btnLoading = false
          this.timer_loading = null
          // this.$message({
          //   message: this.$t('common.timeout'),
          //   type: 'error'
          // })
        }, 60000)
      }
    },
    likeList(val) {
      setReportLikeList(val)
    },
    currentReportItem() {
      const promise1 = this.fetchParam()
      const promise2 = new Promise(resolve => {
        setTimeout(() => {
          resolve()
        }, 750)
      })
      Promise.all([promise1, promise2]).then(() => {
        this.mountedFlag = true
        if ((this.hasVehicleParam && this.form['@WhereObjectID'])
        || (this.hasUserParam && this.form['@WhereHoldID'])
        || (this.hasUserParam2 && this.form['@HoldID'])) {
          this.submit()
        }
      })
    },
    reportParmas(val, oldVal) {
      const skipHoldId = oldVal && oldVal.map(k => k.ParaName).indexOf('@WhereHoldID') != -1 && val.map(k => k.ParaName).indexOf('@WhereHoldID') != -1
      const skipObjectId = oldVal && oldVal.map(k => k.ParaName).indexOf('@WhereObjectID') != -1 && val.map(k => k.ParaName).indexOf('@WhereObjectID') != -1
      const skipObjectId2 = oldVal && oldVal.map(k => k.ParaName).indexOf('@ObjectID') != -1 && val.map(k => k.ParaName).indexOf('@ObjectID') != -1

      // console.log(skipHoldId, skipObjectId, skipObjectId2)
      // 值
      for (let key in this.form) {
        if (key === '@WhereHoldID' && skipHoldId) {
          continue;
        }
        if (key === '@WhereObjectID' && skipObjectId) {
          continue;
        }
        if (key === '@ObjectID' && skipObjectId2) {
          continue;
        }
        this.$delete(this.form, key)
      }

      // 验证
      for (let key in this.rules) {
        if (key === '@WhereHoldID' && skipHoldId) {
          continue;
        }
        if (key === '@WhereObjectID' && skipObjectId) {
          continue;
        }
        if (key === '@ObjectID' && skipObjectId2) {
          continue;
        }
        this.$delete(this.rules, key)
      }

      val.forEach(item => {
        if (item.ParaName.indexOf('@') != -1) {
          if (item.ParaName === '@WhereHoldID' && skipHoldId) {
            return;
          }
          if (item.ParaName === '@WhereObjectID' && skipObjectId) {
            return;
          }
          if (item.ParaName === '@ObjectID' && skipObjectId2) {
            return;
          }
          this.$set(this.form, item.ParaName, item.value ? item.value : null)
        }
      })

      if (this.hasUserParam || this.hasUserParam2) {
        this.holdOptions.length === 0 && this.fetchUserList('')
      }

      if (this.hasVehicleParam || this.hasVehicleParam2) {
        this.vehicleOptions.length === 0 && this.fetchVehicleList()
      }

      this.$emit('loaded')
    },
    reportTableField(val) {
      // 验证
      for (let key in this.visibleRow) {
        this.$delete(this.visibleRow, key)
      }

      val.forEach(item => {
        this.$set(this.visibleRow, item.name, true)
      })
    },
    $route() {
      this.$refs.popover.showPopper = false;
      if (this._inactive) {
        return
      }
      this.routeEnterHandler()
    }
  },
  methods: {
    fetchList() {
      const promise = new Promise((resolve, reject) => {
        API.GetReportList().then(ret => {
          this.list = ret.data.slice()
          resolve()
        }).catch(err => {
          this.$message({
            message: this.$t('common.netError'),
            type: 'error'
          })
          reject(err)
        })
      })
      return promise
    },
    fetchParam() {
      const promise = new Promise((resolve) => {
        API.GetReportParam(this.currentReportItem.FunID).then(ret => {
          const formatSec = {
            'y': 'YYYY',
            'M': 'MM',
            'd': 'DD',
            'h': 'hh',
            'm': 'mm',
            's': 'ss'
          }

          let params = ret.data.list.map(k => {
            if (k.ParaType === 'Date' && k.ValidationExpression === 'yyyy-MM-dd') {
              k.ParaType = 'datetime'
              k.type = 'date'
              let sec = k.DefaultValue.split(',')
              k.value = miment().add(parseInt(sec[1]), formatSec[sec[0]])

              let format = this.formatDefaultValue(sec[2])
              k.value = miment(k.value.format(format))
            }
            if (k.ParaType === 'Date' && k.ValidationExpression === 'yyyy-MM-dd HH:mm:ss') {
              k.ParaType = 'datetime'
              k.type = 'datetime'
              let sec = k.DefaultValue.split(',')
              k.value = miment().add(parseInt(sec[1]), formatSec[sec[0]])

              let format = this.formatDefaultValue(sec[2])
              k.value = miment(k.value.format(format))

            }
            if (k.ParaType === 'Date' && k.ValidationExpression === 'yyyy-MM') {
              k.ParaType = 'datetime'
              k.type = 'month'
              let sec = k.DefaultValue.split(',')
              k.value = miment().add(parseInt(sec[1]), formatSec[sec[0]])

              let format = this.formatDefaultValue(sec[2])
              k.value = miment(k.value.format(format))
            }
            if (k.ParaType === 'Date' && k.ValidationExpression === 'HH:mm:ss') {
              k.ParaType = 'time'
              k.type = 'time'
              let sec = k.DefaultValue.split(',')
              k.value = miment().add(parseInt(sec[1]), formatSec[sec[0]])

              let format = this.formatDefaultValue(sec[2])
              k.value = miment(k.value.format(format))
            }
            if (k.Datas.length > 0) {
              k.ParaType = 'select'
              k.datas = k.Datas
              k.value = k.Datas[0].value
            }
            if (k.ParaType === 'NUMBER' && k.SelectType === 0) {
              k.ParaType = 'number'
              k.value = k.DefaultValue
            }
            if (k.ParaType === 'string') {
              k.ParaType = 'text'
              k.value = k.DefaultValue
            }
            if (k.ParaType === 'System' && k.DefaultValue != null) {
              k.value = k.ValueType ? k.DefaultValue : parseInt(k.DefaultValue)
            }
            k.fieldType = k.ParaType
            return k
          }) // 查询参数


          this.reportParmas = params
          this.reportTableField = ret.data.field  // name prop
          resolve()
        })
      })
      return promise
    },
    fetchUserList(queryKey) {
      this.loadingHold = true
      const promise = new Promise((resolve) => {
        QueryUserList(queryKey).then(ret => {
          this.loadingHold = false
          this.holdOptions = ret.data.map(k => ({
            label: k.value,
            value: k.holdId
          }))
          resolve(ret.data)
        })
      })
      return promise
    },
    fetchVehicleList(queryKey) {
      this.loadingVehicle = true
      const promise = new Promise((resolve) => {
        QueryTreeVehicleListVIP(queryKey, this.form['@WhereHoldID'] || null, this.currentReportItem.FunID).then(ret => {
          this.loadingVehicle = false
          this.vehicleOptions = ret.data.map(k => ({
            label: k.VehicleName,
            sim2: k.SIM2,
            value: k.ObjectID
          }))
          resolve(ret.data)
        })
      })
      return promise
    },
    whereHoldChange() {
      if (!this.hasVehicleParam) {
        return
      }
      this.form['@WhereObjectID'] = null
      this.fetchVehicleList()
    },
    initItem() {
      if (storage.session.get(S_NEXT_REPORT_PARAM)) {
        return this.routeEnterHandler()
      }

      if (this.likeList.length > 0) {
        const index = this.reportItemList.findIndex(k => k.FunID == this.likeList[0])
        if (index != -1) {
          this.currentReportItem = this.reportItemList[index]
          return
        }
      }

      if (this.reportItemList.length > 0) {
        this.currentReportItem = this.reportItemList[0]
      }
    },
    changeItem(item) {
      this.currentReportItem = item
      this.popoverMenu = false
      this.table.data.splice(0)
      this.table.total = 0
      this.searchFormVisible = true
      this.scrollHeight = 0
    },
    toggleLike(item) {
      if (this.isLike(item)) {
        this.disLike(item)
      } else {
        this.like(item)
      }
    },
    isLike(item) {
      return this.likeList.indexOf(`${item.FunID}`) != -1
    },
    like(item) {
      this.likeList.push(`${item.FunID}`)
    },
    disLike(item) {
      deleteFromArray(this.likeList, k => k === `${item.FunID}`)
    },
    isShowRow(name) {
      return this.visibleRow[name]
    },
    bodyMouseWheel(e) {
      this.scrollHandler(e)
    },
    expandSearch() {
      // this.searchFormVisible = true
      // this.$refs.content.scrollTop = 0
    },
    sizeChange(val) {
      this.table.pageSize = val
      this.currentChange()
    },
    currentChange() {
      // this.$refs.content.scrollTop = 0
      API.GetReportDataPage(this.currentReportItem.FunID, JSON.stringify(this.form), this.table.pageIndex, this.table.pageSize).then(ret => {
        this.table.data = ret.data.data
        this.table.total = ret.data.total
      }).catch(err => {
        console.error(err)
      })
    },
    submit() {
      // ***---***
      this.btnLoading = true
      API.GetReportData(this.currentReportItem.FunID, JSON.stringify(this.form), 1, this.table.pageSize).then(ret => {
        this.btnLoading = false
        this.table.data = ret.data.data
        this.table.total = ret.data.total
        this.table.pageIndex = 1
        if (this.table.data.length === 0) {
          this.$message(this.$t('reportQuery.noData'))
        }
      }).catch(err => {
        console.error(err)
        this.btnLoading = false
        this.$message(this.$t('reportQuery.noData') + '.')
      })
    },
    exportData() {
      this.btnExportLoading = true
      let selectedFields = []
      for (let key in this.visibleRow) {
        if (this.visibleRow[key]) {
          const index = this.reportTableField.findIndex(k => k.name === key)
          selectedFields.push(this.reportTableField[index])
        }
      }
      if (selectedFields.length === 0) {
        this.$alert('至少选择一列')
        return
      }

      API.ExportData(this.currentReportItem.FunID, JSON.stringify(this.form), this.currentReportItem.FunName, selectedFields).then(ret => {
        this.btnExportLoading = false
        saveBlob(ret.data, this.$t('reportMenu')[this.currentReportItem.FunName] + '.xlsx')
      })
    },
    getIcon(name) {
      const icons = {
        '基础报表': 'icon-base',
        '里程报表': 'icon-licheng',
        '油耗报表': 'icon-cheliangyouhaochengben',
        '警情报表': 'icon-zuoyebaojing',
        '温度报表': 'icon-wendu',
        '行驶状态报表': 'icon-jiancezhuangtai',
        '区域报表': 'icon-zuoyequyu',
        '其他': 'icon-dots-horizontal'
      }
      return icons[name] || 'icon-base'
    },
    formatDefaultValue(serverFormat) {
      if (!serverFormat || typeof serverFormat !== 'string') {
        console.log('return')
        return serverFormat
      }

      let clientFormat = serverFormat.replace('yyyy', 'YYYY').replace('dd', 'DD').replace('HH', 'hh')

      if (clientFormat.length === 8) {
        clientFormat = 'YYYY-MM-DD ' + clientFormat
      }

      return clientFormat
    },
    setTime(start, end) {
      var index = Math.max(this.reportParmas.map(k => k.ValidationExpression).indexOf('yyyy-MM'),
      this.reportParmas.map(k => k.ValidationExpression).indexOf('yyyy-MM-dd HH:mm:ss'),
      this.reportParmas.map(k => k.ValidationExpression).indexOf('yyyy-MM-dd'))

      var key = this.reportParmas[index].ParaName
      this.form[key] = start
      key = this.reportParmas[index+1].ParaName
      if (this.reportParmas[index+1].ValidationExpression.indexOf('yyyy-MM') != -1) {
        this.form[key] = end
      }

      this.submit()
    },
    setDay1() {
      const now = miment()
      const zero = miment(new Date(now.get('YYYY'), now.get('MM') - 1, now.get('DD')))
      const start = zero
      const end = miment(zero).add(1, 'DD').sub(1, 'ss')
      this.setTime(start, end)
    },
    setDay2() {
      const now = miment()
      const zero = miment(new Date(now.get('YYYY'), now.get('MM') - 1, now.get('DD')))
      const start = miment(zero).add(-1, 'DD')
      const end = miment(zero).sub(1, 'ss')
      this.setTime(start, end)
    },
    setDay3() {
      const now = miment()
      const zero = miment(new Date(now.get('YYYY'), now.get('MM') - 1, now.get('DD')))
      const start = miment(zero).add(-2, 'DD')
      const end = miment(zero).add(-1, 'DD').sub(1, 'ss')
      this.setTime(start, end)
    },
    setDay12() {
      const now = miment()
      const zero = miment(new Date(now.get('YYYY'), now.get('MM') - 1, now.get('DD')));
      console.log(zero+"000***---")
      const start = miment(zero).add(-1, 'DD')
      const end = miment(zero).add(1, 'DD').sub(1, 'ss')
      this.setTime(start, end)
    },
    setDay13() {
      const now = miment()
      const zero = miment(new Date(now.get('YYYY'), now.get('MM') - 1, now.get('DD')))
      const start = miment(zero).add(-2, 'DD')
      const end = miment(zero).add(1, 'DD').sub(1, 'ss')
      this.setTime(start, end)
    },
    setDay14() {
      const now = miment()
      const zero = miment(new Date(now.get('YYYY'), now.get('MM') - 1, now.get('DD')))
      const start = miment(zero).add(-7, 'DD')
      const end = miment(zero).add(1, 'DD').sub(1, 'ss')
      this.setTime(start, end)
    },
    setWeek1() {
      const now = miment()
      const zero = miment(new Date(now.get('YYYY'), now.get('MM') - 1, now.get('DD')))
      const start = miment(zero).firstDayOfWeek().add(1, 'DD')
      const end = miment(zero).add(1, 'DD').sub(1, 'ss')
      this.setTime(start, end)
    },
    setWeek2() {
      const now = miment()
      const zero = miment(new Date(now.get('YYYY'), now.get('MM') - 1, now.get('DD')))
      const start = miment(zero).firstDayOfWeek().add(-6, 'DD')
      const end = miment(zero).firstDayOfWeek().add(1, 'DD').sub(1, 'ss')
      this.setTime(start, end)
    },
    setWeek3() {
      const now = miment()
      const zero = miment(new Date(now.get('YYYY'), now.get('MM') - 1, now.get('DD')))
      const start = miment(zero).firstDayOfWeek().add(-13, 'DD')
      const end = miment(zero).firstDayOfWeek().add(-6, 'DD').sub(1, 'ss')
      this.setTime(start, end)
    },
    setWeek12() {
      const now = miment()
      const zero = miment(new Date(now.get('YYYY'), now.get('MM') - 1, now.get('DD')))
      const start = miment(zero).firstDayOfWeek().add(-6, 'DD')
      const end = miment(zero).add(1, 'DD').sub(1, 'ss')
      this.setTime(start, end)
    },
    setWeek13() {
      const now = miment()
      const zero = miment(new Date(now.get('YYYY'), now.get('MM') - 1, now.get('DD')))
      const start = miment(zero).firstDayOfWeek().add(-13, 'DD')
      const end = miment(zero).add(1, 'DD').sub(1, 'ss')
      this.setTime(start, end)
    },
    setMonth1() {
      const now = miment()
      const zero = miment(new Date(now.get('YYYY'), now.get('MM') - 1, now.get('DD')))
      const month = miment(new Date(now.get('YYYY'), now.get('MM') - 1, 1))
      const start = month
      const end = miment(zero).add(1, 'DD').sub(1, 'ss')
      this.setTime(start, end)
    },
    setMonth2() {
      const now = miment()
      //const zero = miment(new Date(now.get('YYYY'), now.get('MM') - 1, now.get('DD')))
      const month = miment(new Date(now.get('YYYY'), now.get('MM') - 1, 1))
      const start = miment(month).sub(1, 'ss').set(0, 'DD').add(1, 'ss')
      const end = miment(month).sub(1, 'ss')
      this.setTime(start, end)
    },
    setMonth3() {
      const now = miment()
      //const zero = miment(new Date(now.get('YYYY'), now.get('MM') - 1, now.get('DD')))
      const month = miment(new Date(now.get('YYYY'), now.get('MM') - 1, 1))
      const start = miment(month).sub(1, 'ss').set(0, 'DD').add(1, 'ss').set(0, 'DD').sub(1, 'ss').set(0, 'DD').add(1, 'ss')
      const end = miment(month).sub(1, 'ss').set(0, 'DD')
      this.setTime(start, end)
    },
    setMonth12() {
      const now = miment()
      const zero = miment(new Date(now.get('YYYY'), now.get('MM') - 1, now.get('DD')))
      const month = miment(new Date(now.get('YYYY'), now.get('MM') - 1, 1))
      const start = miment(month).sub(1, 'ss').set(0, 'DD').add(1, 'ss')
      const end = miment(zero).add(1, 'DD').sub(1, 'ss')
      this.setTime(start, end)
    },
    setMonth13() {
      const now = miment()
      const zero = miment(new Date(now.get('YYYY'), now.get('MM') - 1, now.get('DD')))
      const month = miment(new Date(now.get('YYYY'), now.get('MM') - 1, 1))
      const start = miment(month).sub(1, 'ss').set(0, 'DD').add(1, 'ss').set(0, 'DD').sub(1, 'ss').set(0, 'DD').add(1, 'ss')
      const end = miment(zero).add(1, 'DD').sub(1, 'ss')
      this.setTime(start, end)
    },
    routeEnterHandler() {

      var params = storage.session.get(S_NEXT_REPORT_PARAM)

      if (params) {

        const FunID = params.FunID

        let allArr = []
        this.list.forEach(k => {
          if (k.Children && k.Children.length) {
            allArr = allArr.concat(k.Children)
          }
        })

        const index = allArr.findIndex(k => k.FunID == FunID)
        if (index == -1) {
          this.currentReportItem = allArr[0]
          return
        }

        this.vehicleOptions.splice(0,this.vehicleOptions.length, {
          label: params.VehicleName,
          sim2: params.SIM2,
          value: parseInt(params.ObjectID)
        })

        if (this.currentReportItem.FunID == FunID) {

          // 当前报表
          this.$nextTick(() => {
            this.form['@WhereObjectID'] = parseInt(params.ObjectID)
            this.submit()
          })

        } else {
          // 非当前报表
          this.$once('loaded',() => {
            this.form['@WhereObjectID'] = parseInt(params.ObjectID)
          })
          this.currentReportItem = allArr[index]
        }
      }

      storage.session.remove(S_NEXT_REPORT_PARAM)
    },
    headerDrag(newWidth, oldWidth, col) {
      if (this.showFieldS.length < 1) {
        return
      }

      let endLabel = this.$t('reportDetail')[this.showFieldS[this.showFieldS.length - 1].name] || this.showFieldS[this.showFieldS.length - 1].name
      console.log(endLabel, col.label)
      if (oldWidth > newWidth && col.label == endLabel ) {
        this.lastCol = parseInt(this.lastCol) + parseInt(oldWidth - newWidth) + 'px'
      }
      console.log(arguments)
    },
    computeTab() {
      this.$nextTick(() => {
        let h = this.$refs.reformDiv.clientHeight
        if (this.hasLongDateParam || this.hasShortDateParam) {
          h = h + 32
        }
        this.tabHeight = `calc(100vh - ${198 + h}px)`
      })
    },
    endResize() {
      this.updateCount++;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/mixin.scss";
@import "../../styles/variables-simple.scss";
@include v-wrapper;
$highlight: $--color-primary;
.sub-title {
  color: inherit;
}
.v-wrapper {
  &.center {
    justify-content: center;
    align-items: center;
  }
  .v-nav {
    @include scroll-bar-grey;
    width:250px;
    background-color: $page-main-background-color;
    z-index: 2;
    // border-left: 1px solid mix($highlight, $--color-white, 50%);
  }
  .v-body {
    position: relative;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 17px;
      height: 17px;
      overflow: auto
    }

    &::-webkit-scrollbar-thumb {
      background-color: #C1C1C1;
      min-height: 25px;
      min-width: 25px;
      border: 1px solid #e0e0e0;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #A8A8A8;
    }
    &::-webkit-scrollbar-thumb:active {
      background-color: #787878;
    }

    &::-webkit-scrollbar-track {
      background-color: #f7f7f7;
      border: 1px solid #efefef
    }
    @mixin title {
      &::before {
        content: ' ';
        border-radius: 50%;
        height: 12px;
        width: 12px;
        background-color: $highlight;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.5em;
      }
      font-size: 14px;
      line-height: 40px;
      padding: 0 18px;
      overflow: visible;
      &.highlight {
        background-color: $page-main-background-color;
        //color: #FCFCFC;
      }
    }

    $padX: 18px;
    .retitle {
      font-size: 14px;
      line-height: 39px;
      height: 40px;
      margin: 0 $padX 12px;
      color: rgba(0, 0, 0, .84);
      font-weight: 600;
      border-bottom: 1px solid rgb(212, 211, 211);
    }
    .retab {
      font-size: 14px;
      color: rgba(0, 0, 0, .54);
      margin: 0 $padX;
      height: 40px;
      line-height: 40px;
      display: flex;
      ol {
        flex: 0 0 auto;
        padding: 0 24px;
        border-top: 1px solid rgb(212, 211, 211);
        border-right: 1px solid rgb(212, 211, 211);
        border-bottom: 1px solid rgb(212, 211, 211);
        cursor: pointer;
        &.active {
          border-bottom: none;
          border-top: 2px solid $highlight;
          color: $highlight;
        }
      }
      & ol:first-child {
        border-left: 1px solid rgb(212, 211, 211);
      }
      .retab-expand {
        flex: 1 1 auto;
        border: none;
        border-bottom: 1px solid rgb(212, 211, 211);
      }
    }
    .reform {
      margin-top: 12px;
      padding: 0 $padX;
      margin-bottom: -12px;
      display: flex;
      justify-content: space-between;
      .el-form-item {
        vertical-align: text-bottom;
        margin-bottom: 12px;
      }
      .el-form-item ::v-deep .el-form-item__label {
        padding-bottom: 0;
      }
      .button-group {
        padding-top: 32px;
        padding-bottom: 12px;
        white-space: nowrap;
      }
    }
    @mixin retable {
      margin: 12px $padX 0;
      position: relative;
      .report-pager {
        margin-top: 0;
        // border: 1px solid #ebeef5;
        border-top: 0;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        position: sticky;
        left: 0;
        bottom: 0;
      }
      & ::v-deep .el-table__empty-block {
        min-height: 170px;
      }
    }
    .retable {
      @include retable;
    }

    .retable-wrapper {
      padding-right: 24px;
      overflow: auto;
      & ::v-deep {
        @include retable;
      }
    }

    .requery ::v-deep {
      .el-form-item__label{
        display: none !important;
        visibility: hidden;
      }
    }

    .risk__title {
      @include title;
      &.fixed {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
      }
    }

    .v-risk-form {
      padding-top: 40px;
      height: 100%;
      overflow: auto;
      @include scroll-bar-grey;
      .risk__title {
        @include title;
      }
      // .risk__form__wrapper {

      // }
      .risk__form {
        font-size: 14px;
        max-width: calc(6em + 220px);
        margin: 0 auto;
        margin-top: 64px;
        margin-bottom: 24px;
        .risk__form__title {
          padding-left: 4em;
          margin-bottom: 36px;
          font-size: 22px;
          // p {
          //   font-weight: 600;
          // }
        }
        &.en {
          max-width: calc(10em + 220px);
        }
        & .el-form-item ::v-deep .el-form-item__content {
          .el-input,.el-select,.el-input-number {
            width: 100%;
          }
        }
      }
    }
  }
  .aside__toogle {
    position: absolute;
    top: 45vh;
    left: 250px;
    transform: translateY(-50px);
    z-index: 3;
    &.collapse {
      left: 0;
    }
    &:hover {
      opacity: .9;
    }
  }
}

.report-query-item {
  height: 40px;
  line-height: 40px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  user-select: none;
  cursor: pointer;
  border-right: 2px solid transparent;
  .item__icon {
    font-size: 18px;
  }
  .item__name {
    padding: 0 0 0 0;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .el-icon-caret-right {
      font-size: 18px;
      transform: translateY(2px);
    }
  }
  &:hover {
    background-color: rgba($--color-primary-light-9, .3);
  }
  &.active {
    border-radius: 2px;
    border-right-color: $highlight;
    background-color: $--color-primary-light-9;
    //color: #FCFCFC;
  }
  &.report-query-item--all {
    cursor: pointer;
  }
}

.risk-history {
  margin: 0 18px 18px;
  position: relative;
  //border: 1px solid #DCDFE6;
  .risk-history__table {
    width: 100%;

  }
  .v-pager {
    background-color: $home-backcolor;
    color: $home-colorprimary;
    padding: 8px 24px;
    & ::v-deep span {
      color: $home-colorprimary;
    }
  }
}

.risk-submit {
  // background-color: $highlight;
  // border-color: $highlight;
  // color: #FCFCFC;
  min-width: 80px;
  border-radius: 20px;
  &:hover {
    // background-color: rgba($highlight, .75);
    // border-color: rgba($highlight, .75);
  }
}

.form-item--expand {
  width: 100%;
  display: block;
}

.risk-refresh {
  cursor: pointer;
  color: $highlight;
  user-select: none;
  &:active {
    color: rgba($highlight, .75);
  }
}

.risk-right {
  float: right;
  height: 40px;
  line-height: 40px;
  display: flex;
  align-items: center;
}

.fullscreen-item {
  background-color: #FFF;
}

.slidegroup-report-enter-active,
.slidegroup-report-leave-active {
  transition: all .3s ease;
}

.slidegroup-report-enter,
.slidegroup-report-leave-active {
  opacity: 0;
}

.slidegroup-report-move {
  transition: all .3s linear;
}

.slidegroup-report-leave-active {
  position: absolute;
}

// .risk-history__table {
//   & ::v-deep .el-table__header-wrapper{
//     // thead tr th{
//     // }
//   }
//   & ::v-deep .el-table__body-wrapper {

//   }
// }
.quick_link {
  margin: 12px 14px 0;
  text-align: left;
  position: relative;
  z-index: 1;
  p{
    width:80%;
  }
  .quick_btn{
    position: absolute;
    top:0;
    right:0;
    z-index: 1;
  }
  span {
    display: inline-block;
    margin: 0 6px;
    font-size: 14px;
  }
}

.report-table {
  border-collapse: collapse;
  margin: 0 auto;
  th, td {
    padding: 0px 12px;
    line-height: 32px;
    height: 32px;
    white-space: nowrap;
    border: 1px solid #EFEFEF;
  }

  th {
    background: linear-gradient(180deg, white 0%, #efeded 100%);
    height: 36px;
    line-height: 36px;
  }

  tr:nth-child(2n+1) {
    background-color: #F4F4F4;
  }
}
.en{
  .v-nav{

  }
}
</style>
