import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

export function GetData(method, paras) {
  const loginKey = getToken()
  return request({
    url: './risk/GetData',
    method: 'post',
    timeout: 60000,
    data: Object.assign({}, commonParams, {
      loginKey,
      method,
      paras
    })
  })
}

export function MCheliangweizhangOptions() {
  const loginKey = getToken()
  return request({
    url: './risk/MCheliangweizhangOptions',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function GetHistory(comment) {
  const loginKey = getToken()
  return request({
    url: './risk/GetHistory',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      comment
    })
  })
}
